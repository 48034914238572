<template>
  <div class="deposit-amount">
    <input class="deposit-amount__value input__item-text" v-model="value" @input="changeAmount">
    <div class="deposit-amount__currency">
      <div class="deposit-amount__currency-selected" @click="showOptions = !showOptions">
        <!-- {{ selectedCurrency }} -->
        <!-- {{ addAccount === 'google' ? $t('currency.currency_symbol') : currency_symbols[currency] }} -->
        <!-- {{ currency_symbols[currency] }} -->
        <!-- <img v-if="isCurrencyChange" src="@/assets/icons/icon-arrow.svg" alt="arrow"> -->
        {{$t('currency.currency_symbol')}}
      </div>
      <!-- <div v-if="showOptions && isCurrencyChange" class="deposit-amount__currency-options">
        <div class="deposit-amount__currency-item" @click="changeCurrency('₽')">
          ₽
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  name: "DepositAmount",
  props: {
    campaignId: {
      type: [String, Number],
      default: null,
    },
    accountName: [String, Number],
    default: null,
    defaultValue: {
      type: String,
      default: null
    },
    isCurrencyChange: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default:''
    },
    addAccount: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: 5000,
      selectedCurrency: '₽',
      showOptions: false,
      currency_symbols: {
        'RUB': '₽',
        'USD': '$',
        'EUR': '€',
        'PLN': 'zł',
      }
    }
  },
  created() {
    // this.initCurrency()
  },
  mounted() {
    if (this.defaultValue) this.value = null
    this.changeAmount()
  },
  computed:{
    currencySymbol() {
      if (this.isCurrencyChange) {
        return this.selectedCurrency==='RUB'?'₽':'$'
      }
      return '₽'
    }
  },
  methods: {
    initCurrency() {
      if(this.$store.getters.language==='en'){
        this.selectedCurrency = 'USD'
      }else if (this.$store.getters.language==='ru') {
        this.selectedCurrency = 'RUB'
      }
    },
    changeCurrency () {
      if(this.isCurrencyChange) {
        this.selectedCurrency = this.selectedCurrency==='USD'?'RUB':'USD'
    
        this.showOptions = false
      }
      
    },
    changeAmount() {
      if (this.campaignId) {
        this.$store.commit('Balance/setDepositAmount', { campaign_id: this.campaignId, amount: +this.value })
      }
      if (this.accountName) {
        this.$store.commit('Balance/setData', { label: 'account', data: +this.value })
      }
    },
  }
}
</script>

<style lang="scss">
.deposit-amount {
  display: flex;

  &__value {
    width: 12rem;
    margin-right: 1rem;
  }

  &__currency {
    position: relative;

    &-selected {
      background: #F1F4FA;
      border-radius: 2px;
      height: 5rem;
      width: 7rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000;
      padding: 0 1rem 0 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &-options {
      position: absolute;
      left: 0;
      top: calc(100% + 1rem);
      border: 1px solid #F1F4FA;
      background: #fff;
      border-radius: 2px;
      width: 7rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000;
      z-index: 2;
    }

      &-selected { 
        background: #F1F4FA;
        border-radius: 2px;
        height: 5rem;
        width: 7rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #000;
        padding: 0 1rem 0 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0;
        justify-content: center;
      }
    &-item {
      padding: 0.75rem 1.5rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &:hover {
        color: #2F80ED;
      }
    }
  }
}
</style>
